import Button from '../../components/inputs/button'
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react'
import {updateAction, getAction} from '../../actions/auth'

export default function Profile(){
    const [activeLink, setActiveLink] = useState(0)
    const dispatch = useDispatch();
    const user = useSelector((state) => state.signIn)
    const profile = user.profile ?? {};
    console.log(profile);
    useEffect(()=>{
        dispatch(getAction())
    }, [])
    return (
        <div className='profile-wrapper'>
            <div className='profile-wrapper_left'>
                <div className='profile-wrapper_left_header'>
                    <img src={require('../../assets/images/logo_header.png').default} alt="" srcset="" />
                </div>
                <div className='profile-wrapper_left_menu'>
                    <div className={activeLink == 0 ?'linkk _active' : 'linkk'} onClick={()=>setActiveLink(0)}>
                        <span>Home</span>
                    </div>
                    <div className={activeLink == 1 ?'linkk _active' : 'linkk'} onClick={()=>setActiveLink(1)}>
                        <span>Payments</span>
                    </div>
                    <div className={activeLink == 2 ?'linkk _active' : 'linkk'} onClick={()=>setActiveLink(2)}>
                        <span>Notification</span>
                    </div>
                    <div className={activeLink == 3 ?'linkk _active' : 'linkk'} onClick={()=>setActiveLink(3)}>
                        <span>Account</span>
                    </div>
                </div>
                <div className='profile-wrapper_left_footer'>
                  <Button>
                    <div className='btn_log'>
                        <img src="" alt="" srcset="" />
                        <span>Log out</span>
                    </div>
                  </Button>
                </div>
            </div>
            <div className='profile-wrapper_right'>
                <div className='profile-wrapper_right_container'>
                    <div className='bg_cover'>
                        <div className='avatr'></div>
                    </div>
                    <div className='sub_header'>
                        <div className='sub_header_title'>
                            <h3>{user.names}</h3>
                            <span>{profile.current_role}</span>
                        </div>
                        <div className='sub_header_status'>
                            <span>Status</span>
                            <div className='status_value'>Pending</div>
                        </div>
                    </div>
                    <div className='right_content'>
                        {activeLink == 0 && <h3>Profile Details</h3>}
                        {activeLink == 1 && <h3>Payments</h3>}
                        {activeLink == 2 && <h3>Notification</h3>}
                        {activeLink == 3 && <h3>Account</h3>}
                        {activeLink == 0 && <div className='right_content_row'>
                            <div className='right_content_row-item'>
                                <div className='right_content_item'>
                                    <h4>Names</h4>
                                    <span>Kayitare prince</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Personal Phone</h4>
                                    <span>{profile.personal_phone}</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Home Phone</h4>
                                    <span>{profile.home_phone}</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Address</h4>
                                    <span>{profile.address}</span>
                                </div>
                            </div>
                            <div style={{width: "20%"}}></div>
                            <div className='right_content_row-item'>
                                <div className='right_content_item'>
                                    <h4>Union</h4>
                                    <span>{profile.member_union}</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Conference</h4>
                                    <span>{profile.conference}</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Church</h4>
                                    <span>{profile.church}</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Country</h4>
                                    <span>{profile.country}</span>
                                </div>
                            </div>
                            <div style={{width: "20%"}}></div>
                            <div className='right_content_row-item'>
                                <div className='right_content_item'>
                                    <h4>Pastors'name</h4>
                                    <span>{profile.pastors_name}</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Pastors'phone</h4>
                                    <span>{profile.pastors_phone}</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Pastors'email</h4>
                                    <span>{profile.pastors_email}</span>
                                </div>
                                <div className='right_content_item'>
                                    <h4>Country</h4>
                                    <span>{profile.country}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}