import Button from "../../components/inputs/button";
import "./index.scss"
import { Link } from 'react-router-dom';

export default function Final(){

    const getQuery = (key)=>{
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == key){return pair[1];}
        }
        return(false);
    }
    
    return (
        <div className="f_wrap">
            <div className="f_center">
                <img src={require('../../assets/images/logo_bg.png').default} alt="" />
                <h1>Thanks for registering!</h1>
                <h2>We will get in touch with you shortly.</h2>
                {getQuery('is_member') != 0 && <Link to="/profile"><div style={{width : '280px'}}><Button>Go to your profile</Button></div></Link>}
            </div>
        </div>
    )
}