import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/index";
import AlertTemplate from "react-alert-template-basic";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import axios from "axios";
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: "80px",
  transition: transitions.SCALE,
};


axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.reponse);
    try{
      if (
        error.response.status == 403 &&
        error.response.config.url !=
        process.env.REACT_APP_D_CORE_URL + "auth/login"
      ) {
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("names");
        localStorage.removeItem("token");
        window.location.href = "/login";
      } else {
        console.log(error.response.config.url);
      }
    }
    catch (err){
      console.log(error)
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
