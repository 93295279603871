import { useEffect, useRef, useState } from 'react'
import './index.scss'
import Label from '../../components/inputs/Label'
import Input from '../../components/inputs/input'
import Button from "../../components/inputs/button";
import { useDispatch, useSelector } from 'react-redux';
import {updateAction, getAction, getCountries, getUnions, getConferences, getChurches} from '../../actions/auth'

export default function Home(){
    const [isMember, setMember] = useState(false)
    const [obj, setObj] = useState(false)
    const [country, setCountry] = useState("")
    const [union, setUnion] = useState("")
    const [conference, setConference] = useState("")
    const [church, setChurch] = useState("")
    const [disabled, setDisabled] = useState(true)
    const memberRef = useRef();
    const iamRef = useRef();
    const unionRef = useRef();
    const genderRef = useRef();
    const spouseRef = useRef();
    const homePhonespousRef = useRef();
    const personalPhonespousRef = useRef();
    const addressRef = useRef();
    const cityRef = useRef();
    const countryRef = useRef();
    const n_memberRef = useRef();
    const postalRef = useRef();
    const roleRef = useRef();
    const schoolRef = useRef();
    const schoolLocationRef = useRef();
    const schoolTypeRef = useRef();
    const schoolDescriptionRef = useRef();
    const fieldRef = useRef();
    const yearsRef = useRef();
    const websiteRef = useRef();
    const methodContactRef = useRef();
    const faithRef = useRef();

    const additionalRef = useRef();
    const churchRef = useRef();
    const conferenceRef = useRef();
    const pastorNameRef = useRef();
    const pastorPhoneRef = useRef();
    const pastorEmailRef = useRef();
    const agreeRef = useRef();

    const dispatch = useDispatch();
    const countries = useSelector((state) => state.signIn.countries)??[];
    const unions = useSelector((state) => state.signIn.unions)??[];
    const conferences = useSelector((state) => state.signIn.conferences)??[];
    const churches = useSelector((state) => state.signIn.churches)??[];

    useEffect(()=>{
        dispatch(getAction())
        dispatch(getCountries({id: ""}))
    }, [])

    const setNames = (type, value)=> {
        if(type == 1)
            setCountry(value)
        else if(type == 2)
            setUnion(value)
        else if(type == 3)
            setConference(value)
        else if(type == 4)
            setChurch(value)
    } 

    const submit = (isMember = true)=>{
        var req = {}
        if(isMember){
            req = {... obj, "spouse" : spouseRef.current.value, "home_phone" :  homePhonespousRef.current.value, "personal_phone" : personalPhonespousRef.current.value,
                   "address" : addressRef.current.value, "country_id" : countryRef.current.value, "city" : cityRef.current.value,
                   "postal" : postalRef.current.value, "description" : schoolDescriptionRef.current.value, "field" : fieldRef.current.value, "years" : yearsRef.current.value,
                   "website" : websiteRef.current.value, "faith": faithRef.current.value, "additional" : additionalRef.current.value, "church_id" : churchRef.current.value,
                   "conference_id" : conferenceRef.current.value, "union_id" : unionRef.current.value, "pastors_name" : pastorNameRef.current.value,
                   "pastors_email" : pastorEmailRef.current.value, "pastors_phone" : pastorPhoneRef.current.value,
                   "country" : country, "union" : union, "conference" : conference, "church" : church}
                   dispatch(updateAction(req))
        }
        else{
            var req = {"is_member" : 0}
            dispatch(updateAction(req))
        }
    }

    const updateArray = (e, key, value)=>{
        var arrayValues = obj[key] ?? [];
        if(e.target.checked){
            arrayValues.push(value)
        }
        else{
            arrayValues.splice(arrayValues.indexOf(value), 1);
        }
        console.log(arrayValues)
        update(key, arrayValues)
    }

    const update = (key, value) =>{setObj({...obj, [key] : value})}

    return <div className="h_wrp_container">
        <div className="h_wrp_left">
            <div className="h_img_container">
                <img src={require('../../assets/images/logo_header.png').default} alt=""/>
            </div>
            {!isMember && <div className='_center header_nb'>
                <h1>Eligibility <br/>Questionnaire</h1>
                </div>}
            {isMember && <div className='_col _left-10'>
                <Label type="label">I am (Required)</Label>
                <Input type="radio" label = "a young adult between the ages of 18 and 35" ref={iamRef} name ="age" onChange={(e)=>{update("iam", "a young adult between the ages of 18 and 35")}} />
                <Input type="radio" label = "a working professional" ref={iamRef}  name ="age"  onChange={(e)=>{update("iam", "a working professional")}} />
                <Input type="radio" label = "a retiree" ref={iamRef}  name ="age"  onChange={(e)=>{update("iam", "a retiree")}} />
                <div style={{height : '20px'}}></div>
                <Label>My Country (Required)</Label>
                <Input type="select" options = {countries} ref={countryRef}  label="Select Country"
                    onChange={(e)=> {dispatch(getUnions({id:e.target.value}))
                     setCountry(e.target.selectedOptions[0].text)}}/>
                <div style={{height : '20px'}}></div>
                <Label type="label">Gender*</Label>
                <Input type="radio" label = "Female" ref={genderRef} name ="gender" value ="Female"  onChange={(e)=>{update("gender", "Female")}} />
                <Input type="radio" label = "Male" ref={genderRef}  name ="gender"  value ="Male"  onChange={(e)=>{update("gender", "Male")}} />
            </div>}    
            <div className="hb_img_container">
            </div>
        </div>
        <div className="h_wrp_right">
            <div className='_col'>
                <Label type="label">I am a baptized Seventh-day Adventist in good standing.</Label>
                <Input type="radio" label = "Yes" ref={memberRef} name ="member" value ="yes" onChange={(e)=>{setMember(true)}}/>
                <Input type="radio" label = "No" ref={memberRef}  name ="member"  value ="no" onChange={(e)=>{setMember(false)}}/>
                {!isMember && <>
                    <div style={{height : '10px'}}></div>
                    <Label type="label">Thank you for your interest in joining ASI. Because ASI members commit themselves to sharing the unique message of the Seventh-day Adventist Church, membership is limited to Seventh-day Adventists. However, non-members are welcome to attend and participate in ASI events and we’d love to stay in touch. May we add you to our mailing list?</Label>
                    <Input type="radio" label = "Yes, please" ref={n_memberRef} name ="n_member" value ="yes"   onChange={(e)=>{update("notify_me", true)}}/>
                    <Input type="radio" label = "No, thank you" ref={n_memberRef}  name ="n_member"  value ="no"   onChange={(e)=>{update("notify_me", false)}}/>
                    <div style={{height : '20px'}}></div>
                    <Button onClick={()=> submit(false)}>Submit</Button>
                </>}
                {isMember && <>
                    <div style={{height : '1400px'}}></div>
                    <Label>Spouse Name</Label>
                    <Input type="text" fullWidth={true} ref={spouseRef}/>
                    <div style={{height : '10px'}}></div>
                    <div className="right_form">
                        <div className="_form_item">
                            <Label>Home phone</Label>
                            <Input type="text" ref={homePhonespousRef}/>
                        </div>
                        <div className="_form_item">
                            <Label>Personal mobile</Label>
                            <Input type="text" ref={personalPhonespousRef}/>
                        </div>
                    </div>
                    <div style={{height : '10px'}}></div>
                    <Label>Address</Label>
                    <Input type="text" fullWidth={true} ref={addressRef}/>
                    <div className="right_form">
                        <div className="_form_item">
                            <Label>City</Label>
                            <Input type="text" ref={cityRef}/>
                        </div>
                        <div className="_form_item">
                            <Label>Postal Code</Label>
                            <Input type="text" ref={postalRef}/>
                        </div>
                    </div>
                    <div style={{height : '10px'}}></div>
                    <Label>Current Role</Label>
                    <Input type="radio" label = "Student or recent graduate" ref={n_memberRef} name ="role"   onChange={(e)=>{update("role", "Student or recent graduate")}} />
                    <Input type="radio" label = "Completing an internship, residency, or other professional training" ref={n_memberRef} name ="role"   onChange={(e)=>{update("role", "Completing an internship, residency, or other professional training")}} />
                    <Input type="radio" label = "Young entrepreneur starting a business" ref={n_memberRef} name ="role"   onChange={(e)=>{update("role", "Young entrepreneur starting a business")}} />
                    <Input type="radio" label = "Other" ref={n_memberRef} name ="role"   onChange={(e)=>{update("role", "Other")}} />
                    <div style={{height : '10px'}}></div>
                    {obj["role"] == "Student or recent graduate" &&<div className="right_form">
                        <div className="_form_item">
                            <Label>Applicant’s school or employing organization</Label>
                            <Input type="text" ref={schoolRef}/>
                        </div>
                        <div className="_form_item">
                            <Label>Location of school or employing organization</Label>
                            <Input type="text" ref={schoolLocationRef}/>
                        </div>
                        <div className="_form_item">
                            <Label>Type of organization</Label>
                            <Input type="text" ref={schoolTypeRef}/>
                        </div>
                    </div>}
                    <div style={{height : '10px'}}></div>
                    <Label>Briefly describe your organization’s activities (yp)</Label>
                    <Input type="textarea"  fullWidth={true} ref={schoolDescriptionRef}/>
                    <div style={{height : '20px'}}></div>
                    <div className="right_form">
                        <div className="_form_item">
                            <Label>Your occupation or major field of study</Label>
                            <Input type="text" ref={fieldRef}/>
                        </div>
                        <div className="_form_item">
                            <Label>Number of years you have held this position</Label>
                            <Input type="number" ref={yearsRef}/>
                        </div>
                        <div className="_form_item">
                            <Label>Organization Website</Label>
                            <Input type="text" ref={websiteRef}/>
                        </div>
                    </div> 
                    <div style={{height : '10px'}}></div>
                    <Label>Preferred methods of contact (yp)</Label>
                    <Input type="radio" label = "Home phone" ref={n_memberRef} name ="contact"   onChange={(e)=>{update("contact", "Home phone")}} />
                    <Input type="radio" label = "Mobile phone" ref={n_memberRef} name ="contact"   onChange={(e)=>{update("contact", "Mobile phone")}} />
                    <Input type="radio" label = "Mail" ref={n_memberRef} name ="contact"  onChange={(e)=>{update("contact", "Mail")}} />
                    <Input type="radio" label = "Email" ref={n_memberRef} name ="contact"  onChange={(e)=>{update("contact", "Email")}} />
                    <div style={{height : '10px'}}></div>
                    <Label>Briefly describe how you share your faith in your professional setting</Label>
                    <Input type="textarea"  fullWidth={true} ref={faithRef}/>
                    <div style={{height : '10px'}}></div>
                    <Label>Preferred methods of contact (yp)</Label>
                    <Input type="checkbox" label = "Health or lifestyle education" ref={n_memberRef} name ="method" onChange={(e)=>{updateArray(e, "methods", "Health or lifestyle education")}} />
                    <Input type="checkbox" label = "Local Bible studies" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "methods", "Local Bible studies")}} />
                    <Input type="checkbox" label = "Prayer ministry" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "methods", "Prayer ministry")}} />
                    <Input type="checkbox" label = "Other" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "methods", "Other")}} />
                    <div style={{height : '10px'}}></div>
                    <div style={{height : '10px'}}></div>
                    <Label>I would be interested in volunteering to assist ASI with the following:</Label>
                    <Input type="checkbox" label = "Annual conference" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Annual conference")}} />
                    <Input type="checkbox" label = "Evangelism presenter" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Evangelism presenter")}} />
                    <Input type="checkbox" label = "Evangelism trainer" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Evangelism trainer")}} />
                    <Input type="checkbox" label = "International missions" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "International missions")}} />
                    <Input type="checkbox" label = "Media (web, video, TV, etc.)" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Media (web, video, TV, etc.)")}} />
                    <Input type="checkbox" label = "Membership recruitment" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Membership recruitment")}} />
                    <Input type="checkbox" label = "Mentoring" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Mentoring")}} />
                    <Input type="checkbox" label = "Newsletter" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Newsletter")}} />
                    <Input type="checkbox" label = "Prayer ministry" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Prayer ministry")}} />
                    <Input type="checkbox" label = "Short-term missions" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Short-term missions")}} />
                    <Input type="checkbox" label = "Other" ref={n_memberRef} name ="method"  onChange={(e)=>{updateArray(e, "interests", "Other")}} />
                    <div style={{height : '10px'}}></div>
                    <Label>Is there any additional information we should know?</Label>
                    <Input type="textarea"  fullWidth={true} ref={additionalRef}/>
                    <div style={{height : '10px'}}></div>
                    <div className="right_form">
                        <div className="_form_item">
                            <Label>Union</Label>
                            <Input type="select" options = {unions} ref={unionRef} label="Select Union" 
                            onChange={(e)=> {dispatch(getConferences({id:e.target.value}))
                            setUnion(e.target.selectedOptions[0].text)}}/>

                        </div>
                        <div className="_form_item">
                            <Label>Conference</Label>
                            <Input type="select" options = {conferences} ref={conferenceRef} label="Select Conference"
                                onChange={(e)=> {dispatch(getChurches({id:e.target.value}))
                                setConference(e.target.selectedOptions[0].text)}}/>
                        </div>
                        <div className="_form_item">
                            <Label>Home Church</Label>
                            <Input type="select" options = {churches} ref={churchRef} label="Select Church" onChange={(e)=>setChurch(e.target.selectedOptions[0].text)}/>
                        </div>
                    </div>
                    <div style={{height : '10px'}}></div>
                    <div className="right_form">
                        <div className="_form_item">
                            <Label>Pastor’s name</Label>
                            <Input type="text" ref={pastorNameRef}/>
                        </div>
                        <div className="_form_item">
                            <Label>Pastor’s phone</Label>
                            <Input type="text" ref={pastorPhoneRef}/>
                        </div>
                        <div className="_form_item">
                            <Label>Pastor’s email</Label>
                            <Input type="text" ref={pastorEmailRef}/>
                        </div>
                    </div>
                    <div style={{height : '20px'}}></div>
                    <Label type='paragraph'>Having read the purpose and objectives of ASI, and recognizing that my business or profession is a ministry, I desire and pledge to uphold the standards and goals of ASI. I commit my life, office, talents, and strengths to “Sharing Christ in the Marketplace.”</Label>
                    <div style={{height : '10px'}}></div>
                    <Label>Consent*</Label>
                    <Input type="checkbox" label = "I agree to the privacy policy" onChange={(e)=>setDisabled(!e.target.checked)} name ="agree"/>
                    <div style={{height : '20px'}}></div>
                    <Button disabled={disabled} onClick={()=> submit()}>Submit</Button>
                    <div style={{height : '20px'}}></div> 

                </>}
            </div>
        </div>
    </div>
}