import Label from "../../components/inputs/Label";
import Button from "../../components/inputs/button";
import Input from "../../components/inputs/input";
import './index.scss'
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/utils/spinner";
import { signinAction } from "../../actions/auth";
import { useRef } from "react";

export default function Login(){

    const loader = useSelector((state) => state.loader);
    const emailRef = useRef();
    const passRef = useRef();
    const dispatch = useDispatch();

    const login = () => {
        console.log("ssss");
      dispatch(
        signinAction(
          {
            email: emailRef.current.value,
            password: passRef.current.value
          }
        )
      );
    };

    return  <div className="wrp_container">
            <div className="wrp_left">
                <div className="img_container">
                    <img src={require('../../assets/images/logo_bg.png').default} alt=""/>
                </div>
            </div>
            <div className="wrp_right">
                <div className="right_link">
                    <p>New user? <a href="/register" className="fgt_link">Create an account</a></p>
                </div>
                <div className="right_center">
                    <h3>Sign in.</h3>
                    <p>sign in using your email address</p>
                    {loader.loginLoading && (
                        <div style={{ marginLeft: "100px", marginTop: "10px" }}>
                            <Spinner />
                        </div>
                    )}
                    {loader.login_form && (
                        <div className="l_message">{loader.login_form}</div>
                    )}
                    <div className="right_form">
                        <div className="_form_item">
                            <Label type="label">Your Email</Label>
                            <Input type="email" ref={emailRef}/>
                        </div>
                        <div className="_form_item">
                            <Label type="label">Password</Label>
                            <Input type="password" ref={passRef}/>
                        </div>
                    </div>
                    <div className="btn_sbt">
                        <Button  onClick={() => login()}>Login</Button>
                    </div>
                </div>
            </div>
        </div>
}