import {combineReducers} from 'redux';
import {loader} from './loader'
import {signIn} from './authReducer'


const rootReducer = combineReducers({
    loader,
    signIn,
})

export default rootReducer