import {createReducer} from '../utility'




export const signIn = createReducer({},{
    "SIGNIN_SUCCESSFUL"(state, {data}){
        return {
            ...state,
            ...data,
        }
     
    } 
})