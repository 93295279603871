import {makeActionCreator} from '../utility'


export const SIGNIN_ACTION = 'SIGNIN_ACTION'
export const signinAction = makeActionCreator(SIGNIN_ACTION, 'data')


export const SIGNUP_ACTION = 'SIGNUP_ACTION'
export const signupAction = makeActionCreator(SIGNUP_ACTION, 'data')

export const UPDATE_ACTION = 'UPDATE_ACTION'
export const updateAction = makeActionCreator(UPDATE_ACTION, 'data')

export const GET_ACTION = 'GET_ACTION'
export const getAction = makeActionCreator(GET_ACTION, 'data')

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const getCountries = makeActionCreator(GET_COUNTRIES, 'data')

export const GET_UNIONS = 'GET_UNIONS'
export const getUnions = makeActionCreator(GET_UNIONS, 'data')

export const GET_CONFERENCES = 'GET_CONFERENCES'
export const getConferences = makeActionCreator(GET_CONFERENCES, 'data')

export const GET_CHURCHES = 'GET_CHURCHES'
export const getChurches = makeActionCreator(GET_CHURCHES, 'data')



export const SIGNIN_SUCCESSFUL = 'SIGNIN_SUCCESSFUL'
export const signinSuccessful = makeActionCreator(SIGNIN_SUCCESSFUL, 'data')
