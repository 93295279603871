import Label from "../../components/inputs/Label";
import Button from "../../components/inputs/button";
import Input from "../../components/inputs/input";
import './index.scss'
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/utils/spinner";
import { signupAction } from "../../actions/auth";
import { useRef } from "react";

export default function Register(){
    const loader = useSelector((state) => state.loader);
    const emailRef = useRef();
    const passRef = useRef();
    const namesRef = useRef();
    const pass2Ref = useRef();
    const dispatch = useDispatch();

    const register = () => {
        console.log("ssss");
      dispatch(
        signupAction(
          {
            email: emailRef.current.value,
            password: passRef.current.value,
            confim_password: pass2Ref.current.value,
            names: namesRef.current.value,
          }
        )
      );
    };

    return  <div className="wrp_container">
            <div className="wrp_left">
                <div className="img_container">
                    <img src={require('../../assets/images/logo_bg.png').default} alt=""/>
                </div>
            </div>
            <div className="wrp_right">
                <div className="right_link">
                    <p>Have an account ? Go back and  <a href="/login" className="fgt_link">login</a></p>
                </div>
                <div className="right_center">
                    <h3>Register .</h3>
                    <p>register with your email address</p>
                    {loader.loginLoading && (
                        <div style={{ marginLeft: "100px", marginTop: "10px" }}>
                            <Spinner />
                        </div>
                    )}
                    {loader.login_form && (
                        <div className="l_message">{loader.login_form}</div>
                    )}
                    <div className="right_form _col">
                        <div className="_form_item">
                            <Label type="label">Your Names</Label>
                            <Input type="email" ref={namesRef}/>
                        </div>
                        <div className="_form_item">
                            <Label type="label">Your Email</Label>
                            <Input type="email" ref={emailRef}/>
                        </div>
                        <div className="_form_item">
                            <Label type="label">Password</Label>
                            <Input type="password" ref={passRef}/>
                        </div>
                        <div className="_form_item">
                            <Label type="label">Confirm Password</Label>
                            <Input type="password" ref={pass2Ref}/>
                        </div>
                    </div>
                    <div className="btn_sbt">
                        <Button onClick={() => register()}>Register</Button>
                    </div>
                </div>
            </div>
        </div>
}