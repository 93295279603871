import { call, put, takeLatest } from "redux-saga/effects";
import { DCore } from "../config";

import { SIGNIN_ACTION, SIGNUP_ACTION, UPDATE_ACTION, GET_ACTION, signinSuccessful,
  GET_COUNTRIES,GET_CONFERENCES,GET_CHURCHES,GET_GET_UNIONS, GET_UNIONS} from "../actions";
import { updateLoader } from "../actions/updateLoader";

function* signinSaga(payload) {
  try {
    yield put(updateLoader({ login_form: null }));
    yield put(updateLoader({ loginLoading: true }));
    const response = yield call(
      DCore.post, process.env.REACT_APP_D_CORE_URL +"auth/login",
      payload.data,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer`,
      }
    );

    yield put(signinSuccessful(response));
    yield put(updateLoader({ loginLoading: false }));
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("names", response.data.name);
    localStorage.setItem("loggedIn", true);
    if(response.data.completed)
      window.location.href = "/final";
    else
      window.location.href = "/";
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ loginLoading: false }));
    yield put(updateLoader({ login_form: "Invalid credentials!!" }));
  }
}

function* signupSaga(payload) {
  try {
    yield put(updateLoader({ login_form: null }));
    yield put(updateLoader({ loginLoading: true }));
    const response = yield call(
      DCore.post, process.env.REACT_APP_D_CORE_URL +"account/register",
      payload.data,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer`,
      }
    );
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("names", response.data.name);
    localStorage.setItem("loggedIn", true);
    window.location.href = "/";
  } catch (e) {
    console.log(e);
    localStorage.setItem("isLoggedin", false);
    yield put(updateLoader({ loginLoading: false }));
    yield put(updateLoader({ login_form: "Invalid OTP!!" }));
  }
}

function* updateSaga(payload) {
  try {
    const response = yield call(
      DCore.post, process.env.REACT_APP_D_CORE_URL +"account/save-wizard",
      payload.data
    );
    window.location.href = "/final?is_member="+payload.data.is_member;
  } catch (e) {
    console.log(e);
  }
}

function* getSaga() {
  try {
    const response = yield call(
      DCore.get, process.env.REACT_APP_D_CORE_URL +"account/profile");
      yield put(signinSuccessful({...response.data}));
      // if(response.data.completed)
      // window.location.href = "/final";
  } catch (e) {
    console.log(e);
  }
}

function* getCountriesSaga(payload) {
  try {
    const response = yield call(
      DCore.get, process.env.REACT_APP_D_CORE_URL +"select/countries/"+payload.data.id);
      yield put(signinSuccessful({countries : response.data}));
  } catch (e) {
    console.log(e);
  }
}

function* getUnionsSaga(payload) {
  try {
    const response = yield call(
      DCore.get, process.env.REACT_APP_D_CORE_URL +"select/unions/"+payload.data.id);
      yield put(signinSuccessful({unions : response.data}));
  } catch (e) {
    console.log(e);
  }
}

function* getConferencesSaga(payload) {
  try {
    const response = yield call(
      DCore.get, process.env.REACT_APP_D_CORE_URL +"select/conferences/"+payload.data.id);
      yield put(signinSuccessful({conferences : response.data}));
  } catch (e) {
    console.log(e);
  }
}

function* getChurchesSaga(payload) {
  try {
    const response = yield call(
      DCore.get, process.env.REACT_APP_D_CORE_URL +"select/churches/"+payload.data.id);
      yield put(signinSuccessful({churches : response.data}));
  } catch (e) {
    console.log(e);
  }
}


export function* authSaga() {
  yield takeLatest(SIGNIN_ACTION, signinSaga);
  yield takeLatest(SIGNUP_ACTION, signupSaga);
  yield takeLatest(UPDATE_ACTION, updateSaga);
  yield takeLatest(GET_ACTION, getSaga);
  yield takeLatest(GET_COUNTRIES, getCountriesSaga);
  yield takeLatest(GET_UNIONS, getUnionsSaga);
  yield takeLatest(GET_CONFERENCES, getConferencesSaga);
  yield takeLatest(GET_CHURCHES, getChurchesSaga);
}
