import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {useState} from "react";
import { DCore } from "./config";
import Login from "./pages/login/login";
import Register from "./pages/login/register";
import Home from "./pages/home";
import Final from "./pages/final";
import Profile from "./pages/profile";


function App() {
  const isLoggedIn =(localStorage.getItem("loggedIn") ?? false) || window.location.pathname == "/register";
   return isLoggedIn ? <Router>
    <div className="m_container">
      <div className="main">
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="/final">
            <Final/>
          </Route>
          <Route path="/profile">
            <Profile/>
          </Route>
          <Route path="/login">
            <Login/>
          </Route>
          <Route path="/register">
            <Register/>
          </Route>
        </Switch>
      </div>
    </div>
  </Router> :
  (
    <Login />
  );
  
}

export default App;
