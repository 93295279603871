import axios from "axios";

const { REACT_APP_D_CORE_URL } = process.env;

const AppUrl = REACT_APP_D_CORE_URL;
const token = "Bearer "+localStorage.getItem("token") ?? "";

const get = async (
  url,
  data = {},
  headers = {
    "Content-Type": "application/json",
    Authorization: token,
  }
) => {
  return await request("GET", url, data, headers);
};

const put = async (
  url,
  data,
  headers = {
    "Content-Type": "application/json",
    Authorization: token,
  }
) => {
  return await request("PUT", url, data, headers);
};

const post = async (
  url,
  data,
  headers = {
    "Content-Type": "application/json",
    Authorization: token,
  }
) => {
  return await request("POST", url, data, headers);
};

const destroy = async (
  url,
  data,
  headers = {
    "Content-Type": "application/json",
    Authorization:  token,
  }
) => {
  return await request("DELETE", url, data, headers);
};

const request = async (method, url, data, headers) => {
  const req = await axios({
    headers,
    method,
    url,
    data,
  });
  return req;
};

export const DCore = {
  get,
  post,
  put,
  destroy,
  request,
};
