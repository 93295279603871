import "./input.scss"
import React from 'react';

const Input = React.forwardRef(({type = "text", label = "",  name ="", options = [],  onChange, fullWidth = false}, ref) =>{
    return (type == "radio" || type == "checkbox")  ?
    <div className="input-container">
        <input type={type} ref={ref} name={name} onChange={onChange}/>
        <span>{label}</span>
    </div> : type == "textarea"? <textarea className={fullWidth ? "_max-width" : ""} name="" id="" cols="30" rows="10" ref={ref}></textarea>:
    type == "select"? <select name={name} onChange={onChange} ref={ref}>
        {label!="" && <option>{label}</option>}
        {options.map((option) => <option value={option.id}>{option.name}</option>)}
    </select> : <input type={type} ref={ref} className={fullWidth ? "_max-width" : ""} />
})

export default Input